import { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { API, MSG } from "../helper/Constants";
import reducer from "../reducer/OccasionalExpenseReducer";
import api from "../services/AxiosConfig";
import { useAuthContext } from "./AuthContext";

const OccasionalExpenseContext = createContext();

const initialState = {
    isProcessing: false,
    occasions: [],
    occasionWiseExpenses: new Map(),
    occasionWiseTotalExpenditure: new Map(),
    currentExpTotal: new Map()
}

const successToast = (msg) => {
    toast.success(msg, { position: "top-center", autoClose: 3000 });
}

const errorToast = (msg) => {
    toast.error(msg, { position: "top-center", autoClose: 3000 });
}

const OccasionalExpenseContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { loginSuccess } = useAuthContext();

    const user = JSON.parse(localStorage.getItem("user"));

    const fetchAllData = async () => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await api.get(API.FETCH_OCCASIONAL_EXPENSE_DATA);
            const occasions = await response.data.occasions ? response.data.occasions : [];
            const occasionWiseExpenses = await response.data.occasionWiseExpenses ? response.data.occasionWiseExpenses : new Map();
            const occasionWiseTotalExpenditure = await response.data.occasionWiseTotalExpenditure ? new Map(Object.entries(response.data.occasionWiseTotalExpenditure)) : new Map();
            const currentExpTotal = new Map();
            for (const occasion of occasions) {
                if (occasionWiseTotalExpenditure.has(occasion.id)) {
                    currentExpTotal.set(occasion.name, occasionWiseTotalExpenditure.get(occasion.id));
                    break;  // Exit the loop once a match is found
                }
            }
            if (response.status === 200) {
                dispatch({ type: "FETCH_ALL_SUCCESS", payload: { occasions, occasionWiseExpenses, occasionWiseTotalExpenditure, currentExpTotal } })
            } else {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
                dispatch({ type: "PROCESSING", payload: false })
            }
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const addOccasion = async (category) => {
        if (!category) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await api.post(API.ADD_OCCASION, { "name": category, "user": `${user?.firstName} ${user?.lastName}` });
            fetchAllData();
            successToast(MSG.NEW_OCCASION_ADDED);
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const addExpense = async (data) => {
        if (!data) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await api.post(API.ADD_OCCASIONAL_EXPENSE, { ...data, "user": `${user?.firstName} ${user?.lastName}` });
            fetchAllData();
            successToast(MSG.NEW_EXPENSE_ADDED);
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const updateExpense = async (data) => {
        if (!data) {
            return
        }
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await api.put(API.UPDATE_OCCASIONAL_EXPENSE, data);
            fetchAllData();
            successToast(MSG.EXPENSE_DETAILS_UPDATED);
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const toggleOccasionStatus = async (occasionId) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await api.put(API.TOGGLE_OCCASION_STATUS.replace('#', occasionId));
            fetchAllData();
            successToast(MSG.OCCASION_STATUS_UPDATED);
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    useEffect(() => {
        (user || loginSuccess) && fetchAllData();
        // eslint-disable-next-line
    }, [loginSuccess])

    return <OccasionalExpenseContext.Provider value={{ ...state, fetchAllData, addOccasion, toggleOccasionStatus, addExpense, updateExpense }}>{children}</OccasionalExpenseContext.Provider>

}

const useOccasionalExpenseContext = () => {
    return useContext(OccasionalExpenseContext);
}

export { OccasionalExpenseContextProvider, useOccasionalExpenseContext };

