import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthRouter = () => {
    let authToken = localStorage.getItem('user');
    const location = useLocation();

    if (!authToken) {
        return (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    }
    return (
        <Outlet />
    )
}

export default AuthRouter