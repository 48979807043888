import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { MdAdd, MdClose, MdCurrencyRupee } from 'react-icons/md';
import { useOccasionalExpenseContext } from '../../context/OccasionalExpenseContext';

const AddExpenseModal = () => {
    const { isProcessing, addExpense, occasions, occasionWiseExpenses } = useOccasionalExpenseContext();
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();

    useEffect(() => {
        let activeOccasions = occasions?.filter(o => o.active)
        reset({
            date: new Date().toISOString().split('T')[0],
            occasion: { id: activeOccasions?.length === 1 ? activeOccasions[0]?.id : "" }
        })
    }, [occasions, reset])

    const onSubmit = (data) => {
        document.getElementById('close_add_expense_modal').click();
        reset();
        addExpense(data);
    };

    //-----------Expense Details Suggesions Implementation----------
    const [isClicked, setIsClicked] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const detailsInput = useWatch({ control, name: "details" })

    useEffect(() => {
        if (detailsInput && !isClicked) {
            const selectedOccasionExps = occasionWiseExpenses[document.getElementById("occasion").value]
            const uniqueDetails = selectedOccasionExps && selectedOccasionExps?.reduce((acc, suggestion) => {
                const details = suggestion?.details;
                const detailsLower = details?.toLowerCase();
                if (detailsLower && detailsLower.includes(detailsInput.toLowerCase()) && !acc.has(detailsLower)) {
                    acc.add(details);
                }
                return acc;
            }, new Set());
            const filtered = uniqueDetails ? Array.from(uniqueDetails) : [];
            setFilteredSuggestions(filtered);
        } else {
            setFilteredSuggestions([]);
        }
    }, [detailsInput, isClicked, occasionWiseExpenses]);

    const handleDetailsChange = (event) => {
        setValue('details', event.target.value);
        setIsClicked(false);
    };

    const handleDetailsClick = (suggestion) => {
        setValue('details', suggestion);
        setIsClicked(true);
    };
    //------------------------------------------------------------

    return (
        <dialog id="Add_Expense_Modal" className="modal backdrop:bg-black/60 flex items-center justify-center">
            <div className="relative m-6 w-full max-w-md max-h-full md:max-w-xl rounded-xl bg-gradient-to-br from-gray-100 to-white shadow-[0_10px_30px_rgba(0,0,0,0.3)] transform transition-all duration-300 hover:shadow-[0_20px_40px_rgba(0,0,0,0.5)]">
                {/* Modal content */}
                <div className="relative bg-white rounded-xl shadow-lg overflow-hidden">
                    {isProcessing && (
                        <div className="absolute w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                            <svg aria-hidden="true" className="absolute w-8 h-8 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 bg-gradient-to-r from-green-200 to-green-100 shadow-inner rounded-t-xl">
                        <h3 className="text-lg font-bold text-gray-800 tracking-wide">Add Expense</h3>
                        <form method="dialog">
                            <button
                                onClick={() => reset()}
                                id="close_add_expense_modal"
                                className="text-gray-500 hover:text-gray-700 p-2 rounded-full bg-gradient-to-r from-red-300 to-red-400 shadow-[0_4px_8px_rgba(0,0,0,0.2)] hover:shadow-[0_6px_12px_rgba(0,0,0,0.3)] transform hover:scale-105 transition"
                            >
                                <MdClose className="w-6 h-6" />
                            </button>
                        </form>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-6 pt-6 pb-2 bg-gradient-to-br from-gray-50 to-gray-100 rounded-b-xl">
                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-1 sm:col-span-1">
                                    <label
                                        htmlFor="date"
                                        className="ml-1 block mb-2 text-sm font-medium  dark:text-white"
                                    >
                                        Date
                                    </label>
                                    <input
                                        type="date"
                                        name="date"
                                        id="date"
                                        className=" border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        {...register("date", { required: "Required" })}
                                    />
                                    {errors.date && <p className="text-red-500 text-xs italic">Date Required</p>}
                                </div>
                                <div className="col-span-1 sm:col-span-1">
                                    <label
                                        htmlFor="desc"
                                        className="ml-1 block mb-2 text-sm font-medium  dark:text-white"
                                    >
                                        Amount
                                    </label>
                                    <MdCurrencyRupee className='ml-1.5 mt-3 absolute' />
                                    <input
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        className="pl-6 border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Enter Amount"
                                        {...register("amount", { required: "Required" })}
                                    />
                                    {errors.amount && <p className="text-red-500 text-xs italic">Amount Required</p>}
                                </div>
                                <div className="col-span-2 sm:col-span-2">
                                    <label
                                        htmlFor="occasion"
                                        className="ml-1 block mb-2 text-sm font-medium  dark:text-white"
                                    >
                                        Occasion
                                    </label>
                                    <select
                                        id="occasion"
                                        className="border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        {...register("occasion.id", { required: "Required" })}
                                    >
                                        <option value="">Select Occasion</option>
                                        {occasions?.filter(o => o.active).map((occasion, index) => (
                                            <option key={index} value={occasion.id}>{occasion.name}</option>
                                        ))}
                                    </select>
                                    {errors.occasion && <p className="text-red-500 text-xs italic">Select Occasion</p>}
                                </div>
                            </div>

                            <div className="grid gap-4 mb-4 grid-cols-2">
                                <div className="col-span-2">
                                    <label
                                        htmlFor="details"
                                        className="ml-1 block mb-2 text-sm font-medium  dark:text-white"
                                    >
                                        Expense Details
                                    </label>
                                    <textarea
                                        id="details"
                                        rows={1}
                                        onInput={handleDetailsChange}
                                        className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Write Expense Details Here"
                                        {...register("details", { required: "Required" })}
                                    />
                                    {filteredSuggestions?.length > 0 && (
                                        <ul className="border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        >
                                            {filteredSuggestions?.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleDetailsClick(suggestion)} className='cursor-pointer hover:bg-neutral-200 px-2.5 py-2'>
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {errors.details && <p className="text-red-500 text-xs italic">Expense Details Required</p>}
                                </div>
                            </div>
                        </div>
                        <div className={`py-2 border-t border-gray-200 dark:border-gray-700 flex justify-center items-center bg-gradient-to-r from-green-200 to-green-100`}>
                            <button
                                type="submit"
                                className="relative group overflow-hidden text-white inline-flex items-center bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-500 hover:to-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 py-3 shadow-md transition-all duration-300 dark:focus:ring-blue-800"
                            >
                                <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 opacity-50 group-hover:opacity-100 transition-opacity duration-300"></span>
                                <span className="relative flex items-center gap-2 z-10">
                                    <MdAdd className="w-5 h-5" />
                                    Add Expense
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </dialog>

    )
}

export default AddExpenseModal