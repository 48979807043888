import { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { API, MSG } from "../helper/Constants";
import reducer from "../reducer/AuthReducer";
import api from "../services/AxiosConfig";
import TokenService from "../services/TokenService";

const AuthContext = createContext();

const initialState = {
    isProcessing: false,
    address: null,
    loginSuccess: false,
    users: []
}

const user = JSON.parse(localStorage.getItem("user"));

const successToast = (msg) => {
    toast.success(msg, { position: "top-center", autoClose: 3000 });
}

const errorToast = (msg) => {
    toast.error(msg, { position: "top-center", autoClose: 3000 });
}

const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const loginUser = async (userName, password, setIsLoggedIn) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await api.post(API.LOGIN, {
                userName: userName,
                password: password
            });
            const userDetails = await response.data;
            if (response.status === 200) {
                successToast(MSG.LOGIN_SUCCESS)
                TokenService.setUser(response.data);
                userDetails?.role === 'ADMIN' && getAllUsers();
                setIsLoggedIn(true)
                dispatch({ type: "LOGIN_SUCCESS" })
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                errorToast(MSG.INVALID_USER)
                dispatch({ type: "LOGIN_FAILURE" })
            } else if (error.response && error.response.status === 400) {
                errorToast(MSG.USER_INACTIVE)
                dispatch({ type: "LOGIN_FAILURE" })
            } else {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
                dispatch({ type: "LOGIN_FAILURE" })
            }
        }
    }

    const registerUser = async (data, setIsRegistationSuccess) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await api.post(API.REGISTER, data);
            await response.data;
            if (response.status === 200) {
                successToast(MSG.REGISTER_SUCCESS)
                setIsRegistationSuccess(true)
                dispatch({ type: "LOGIN_SUCCESS" })
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                errorToast(MSG.REGISTER_FAIL)
            } else {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
            dispatch({ type: "LOGIN_FAILURE" })
        }
    }

    const toggleUserStatus = async (userId) => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            await api.put(API.TOGGLE_USER_STATUS.replace('#', userId));
            getAllUsers();
            successToast(MSG.USER_STATUS_UPDATED);
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const getAllUsers = async () => {
        dispatch({ type: "PROCESSING", payload: true })
        try {
            const response = await api.get(API.GET_ALL_USERS);
            const users = await response.data ? response.data : []
            dispatch({ type: "FETCH_USERS_SUCCESS", payload: users })
        } catch (error) {
            dispatch({ type: "PROCESSING", payload: false })
            if (error?.response?.data?.status !== 401) {
                errorToast(MSG.INTERNAL_SERVER_ERROR)
            }
        }
    }

    const logout = async (setIsLoggedIn) => {
        try {
            await api.post(API.LOGOUT);
        } catch (error) {
        }
        TokenService.removeUser();
        setIsLoggedIn(false)
        successToast(MSG.LOGOUT_SUCCESS)
        dispatch({ type: "LOGOUT_SUCCESS" });
    }

    useEffect(() => {
        user && user?.role === 'ADMIN' && getAllUsers();
        // eslint-disable-next-line
    }, [user])

    return <AuthContext.Provider value={{ ...state, loginUser, logout, dispatch, registerUser, toggleUserStatus }}>{children}</AuthContext.Provider>

}

const useAuthContext = () => {
    return useContext(AuthContext);
}

export { AuthContextProvider, useAuthContext };

