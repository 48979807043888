import React from 'react';
import { useOccasionalExpenseContext } from '../../context/OccasionalExpenseContext';
import DocumentTitle from '../../helper/DocumentTitle';
import Loading from '../../helper/Loading';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';

function ExpenseReportPage() {

    const { occasionWiseTotalExpenditure, occasions, isProcessing } = useOccasionalExpenseContext();
    DocumentTitle("Report");

    return isProcessing ? <Loading /> : (
        <>
            <div className="md:flex gap-6 md:pl-6">
                <div className="w-full md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-center py-4">
                            <p className="text-xl text-headingColor font-bold capitalize">
                                Occasion-wise Expense Details
                            </p>
                        </div>
                        <div>
                            <hr className="w-52 h-1 mx-auto border-0 rounded bg-cust-red" />
                        </div>
                    </div>
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            {/* <th scope="col" className="px-4 md:px-6 py-4">
                                                #
                                            </th> */}
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Occasion
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Expenditure
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(occasionWiseTotalExpenditure.entries()).map(([key, value], index) => (
                                            <tr key={key} className="border-b cursor-default border-neutral-300 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                {/* <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{index + 1}</td> */}
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{occasions?.find(o => o.id === key)?.name}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><PriceFormat price={value} /></td>
                                            </tr>
                                        ))}
                                        {occasionWiseTotalExpenditure?.length === 0 &&
                                            <tr><td colSpan={3} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

const ExpenseReport = () => {
    return <DashboardTemplate MainSection={ExpenseReportPage()} Page={"Report"} Heading={"Occasional"} />
}

export default ExpenseReport