import "@fontsource/poppins";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { MdAssignment, MdAttachMoney, MdCalendarMonth, MdClose, MdDashboard, MdFormatListBulleted, MdHome, MdLogout, MdMenu, MdPeople, MdPerson } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useDailyExpenseContext } from "../../context/DailyExpenseContext";
import { useOccasionalExpenseContext } from "../../context/OccasionalExpenseContext";
import { LOGO, SITE_NAME } from "../../helper/Constants";
import PriceFormat from "../../helper/PriceFormat";
import MenuItem from "./MenuItem";

const DashboardTemplate = ({ MainSection, Heading, Page }) => {
    const [show, setShow] = useState(false);
    const [openSideBar] = useState(true);
    const [menuSelected, setMenuSelected] = useState("Dashboard");
    const today = new Date();
    const daysLeft = (new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()) - (today.getDate());
    const { curMonthExpAmount } = useDailyExpenseContext();
    const { currentExpTotal } = useOccasionalExpenseContext();

    const user = JSON.parse(localStorage.getItem("user"));

    const openSidebar = () => {
        setShow(!show);
    };

    useEffect(() => {
        setMenuSelected(Page)
    }, [Page])

    const dailyExpMenu = [
        {
            name: "Dashboard",
            link: "/",
            subMenus: [],
            svg: <MdHome className={`group-hover:fill-white text-2xl hover:text-white ${menuSelected === "Dashboard" ? "" : "fill-[#233047]"}`} />,
        },
        {
            name: "Categories",
            link: "/dailyExpenses/categories",
            subMenus: [],
            svg: <MdFormatListBulleted className={`group-hover:fill-white text-2xl ${menuSelected === "Categories" ? "" : "fill-[#233047]"}`} />,
        },
        {
            name: "Reports",
            link: "/",
            subMenus: [{
                name: "Monthly Report",
                link: "/dailyExpenses/monthlyReport",
            },
            {
                name: "Category Report",
                link: "/dailyExpenses/categoryReport",
            }],
            svg: <MdAssignment className={`text-2xl ${menuSelected === "Monthly Report" ? "" : "fill-[#233047]"}`} />,
        },
    ];

    const occasionalExpMenu = [
        {
            name: "Expenses",
            link: "/occasionalExpenses/dashboard",
            subMenus: [],
            svg: <MdDashboard className={`group-hover:fill-white text-2xl ${menuSelected === "Expenses" ? "" : "fill-[#233047]"}`} />,
        },
        {
            name: "Occasions",
            link: "/occasionalExpenses/occasions",
            subMenus: [],
            svg: <MdFormatListBulleted className={`group-hover:fill-white text-2xl ${menuSelected === "Occasions" ? "" : "fill-[#233047]"}`} />,
        },
        {
            name: "Report",
            link: "/occasionalExpenses/report",
            subMenus: [],
            svg: <MdAssignment className={`group-hover:fill-white text-2xl ${menuSelected === "Report" ? "" : "fill-[#233047]"}`} />,
        },
    ];

    let userMenu = {
        name: `${user?.firstName} ${user?.lastName ? user.lastName.charAt(0) : ''}`,
        link: "/",
        subMenus: [],
        svg: <MdPerson className="text-2xl fill-[#233047]" />,
    };

    // Conditionally add submenus based on user role
    if (user && user.role === 'ADMIN') {
        userMenu.subMenus.push({
            name: "Manage Users",
            link: "/users",
            svg: <MdPeople className="w-6 h-6" />
        });
    }

    // Always add logout submenu
    userMenu.subMenus.push({
        name: "Logout",
        link: "/auth/logout",
        svg: <MdLogout className="w-6 h-6" />
    });


    return (
        <div className={`w-full md:max-h-[100vh] admin-analytics bg-[#F9F9F9] font-poppins overflow-hidden md:bg-white`}>
            <div className="w-full flex ">
                <div className={`no-scrollbar min-h-screen bg-gray-100 shadow-lg z-20 lg:px-6 py-8 transition-all duration-500 ease-in-out sm:flex flex-col gap-1 absolute top-0 sm:relative sm:left-0 h-full overflow-hidden ${show ? "left-0 w-72 overflow-y-auto" : "-left-72 w-72 sm:left-0"} ${openSideBar ? "sm:w-72 md:w-80 px-6" : "sm:w-20 px-3"}`}>
                    {/* Logo Section */}
                    <div className={`flex gap-3 items-center mb-6 ${openSideBar ? "sm:justify-start pl-0" : "sm:justify-center pl-0"}`}>
                        <Link to="/">
                            <motion.div whileHover={{ scale: 1.1 }} className="flex items-center gap-3 cursor-pointer">
                                <img src={LOGO} alt="Logo" className="w-12 md:w-14 object-cover" />
                                <p className={`text-gray-800 md:text-lg lg:text-xl font-bold transition-opacity duration-300 ${openSideBar ? "opacity-100" : "opacity-0"}`}>
                                    {SITE_NAME}
                                </p>
                            </motion.div>
                        </Link>
                    </div>

                    {/* Sidebar Toggle */}
                    <MdClose onClick={openSidebar} className={`absolute top-9 right-4 sm:hidden text-gray-600 hover:text-gray-800 w-8 h-8 rounded-lg shadow-md bg-white hover:bg-gray-100 border border-gray-300 transition-all duration-200 ease-in-out cursor-pointer ${show ? " block z-30" : "hidden"}`} />

                    <div className="flex flex-col gap-6 pt-4">
                        {/* Daily Section */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center text-sm text-gray-600 font-semibold uppercase tracking-wide">
                                <span>Daily</span>
                                <div className="flex-grow border-t border-gray-300 mx-3"></div>
                            </div>
                            {dailyExpMenu?.map((data, index) => (
                                <MenuItem menu={data} setMenuSelected={setMenuSelected} openSideBar={openSideBar} menuSelected={menuSelected} key={index} />
                            ))}
                        </div>

                        {/* Occasional Section */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center text-sm text-gray-600 font-semibold uppercase tracking-wide">
                                <span>Occasional</span>
                                <div className="flex-grow border-t border-gray-300 mx-3"></div>
                            </div>
                            {occasionalExpMenu?.map((data, index) => (
                                <MenuItem menu={data} setMenuSelected={setMenuSelected} openSideBar={openSideBar} menuSelected={menuSelected} key={index} />
                            ))}
                        </div>

                        {/* User Section */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center text-sm text-gray-600 font-semibold uppercase tracking-wide">
                                <span>User</span>
                                <div className="flex-grow border-t border-gray-300 mx-3"></div>
                            </div>
                            <MenuItem menu={userMenu} setMenuSelected={setMenuSelected} openSideBar={openSideBar} menuSelected={menuSelected} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className={`max-h-[100vh] flex w-full gap-7 transition-all duration-1000 ease-in-out md:mt-0`} >
                        <div className={`flex flex-col w-full`} >
                            <div
                                onClick={openSidebar}
                                className="fixed top-4 left-2 sm:hidden p-3 cursor-pointer rounded shadow-md bg-gradient-to-r from-white via-gray-100 to-gray-200 hover:from-gray-200 hover:via-gray-300 hover:to-gray-400 hover:scale-105 transition-transform duration-300 ease-in-out group"
                            >
                                <MdMenu className="text-gray-800 w-6 h-6 group-hover:text-gray-900 group-hover:scale-110 transition-transform duration-300" />
                            </div>
                            {Heading === 'Daily' ? renderDailyHeading(daysLeft, curMonthExpAmount) : renderOccasionalHeading(currentExpTotal)}
                            {MainSection}

                            <div className={` block ${openSideBar ? " lg:hidden" : "md:hidden"}`} >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function renderDailyHeading(daysLeft, curMonthExpAmount) {
    return (
        <div className="flex flex-col grid grid-cols-12 gap-2 pl-4 pr-2 md:pt-6 py-4 ml-12">
            <div className="col-span-6 sm:col-span-6 md:col-span-3">
                <div className="flex flex-row bg-white shadow-sm border border-gray-300 rounded-lg pr-2 pl-3 py-2">
                    <div className="flex items-center justify-center flex-shrink-0 h-8 w-8 rounded-xl bg-orange-200 text-orange-500">
                        <MdCalendarMonth className="w-6 h-6" />
                    </div>
                    <div className="flex flex-col flex-grow ml-2 justify-center">
                        {/* <div className="text-sm text-gray-500">Days Left</div> */}
                        <div><span className="font-bold text-lg">{daysLeft}</span> <span className="font-medium md:font-normal text-xs md:text-base">Days Left</span></div>
                    </div>
                </div>
            </div>
            <div className="col-span-6 sm:col-span-6 md:col-span-3">
                <div className="flex flex-row bg-white shadow-sm border border-gray-300 rounded-lg px-4 py-2">
                    <div className="flex items-center justify-center flex-shrink-0 h-8 w-8 rounded-xl bg-green-200 text-green-500">
                        <MdAttachMoney className="w-6 h-6" />
                    </div>
                    <div className="flex flex-col flex-grow ml-4 justify-center">
                        {/* <div className="text-sm text-gray-500">Expenses</div> */}
                        <div className="font-bold text-lg"><PriceFormat price={curMonthExpAmount} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function renderOccasionalHeading(currentExpTotal) {
    let occasion = "", amount = 0
    if (currentExpTotal.size > 0) {
        [occasion, amount] = currentExpTotal.entries().next().value;
    }
    return (
        <div className="flex flex-col grid grid-cols-12 gap-2 pl-4 pr-2 md:pt-6 py-4 ml-12">
            <div className="col-span-12 sm:col-span-10 md:col-span-3">
                <div className="grid grid-cols-8 gap-1 bg-white shadow-sm border border-gray-300 rounded-lg px-3 py-2 items-center">
                    <div className="col-span-1 flex items-center justify-center h-8 w-8 rounded-xl bg-red-100 text-red-500">
                        <MdAttachMoney className="w-6 h-6" />
                    </div>
                    <div className="col-span-4 justify-left border-r-2 border-red-500">
                        <div className="font-bold text-lg"><PriceFormat price={amount} /></div>
                    </div>
                    <div className="col-span-3 mx-2 justify-center">
                        <div className="text-sm text-gray-700">{occasion}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardTemplate;