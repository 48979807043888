import axios from "axios";
import { API } from "../helper/Constants";
import TokenService from "./TokenService";

const instance = axios.create({
    baseURL: API.BACKEND_HOST,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        const refreshToken = TokenService.getLocalRefreshToken();
        if (config.url.includes('/logout') && refreshToken) {
            config.headers["Authorization"] = 'Bearer ' + refreshToken;
        }
        else if (!config.url.includes('/login') && token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== API.LOGIN_URI && originalConfig.url !== API.REFRESH_TOKEN_URI && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then(token => {
                            originalConfig.headers['Authorization'] = `Bearer ${token}`;
                            return axios(originalConfig);
                        })
                        .catch(err => Promise.reject(err));
                }
                originalConfig._retry = true;
                isRefreshing = true;

                return new Promise((resolve, reject) => {
                    instance
                        .post(API.REFRESH_TOKEN_URI, {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        })
                        .then(({ data }) => {
                            TokenService.updateLocalAccessToken(data.accessToken);
                            instance.defaults.headers['Authorization'] = `Bearer ${data.accessToken}`;
                            originalConfig.headers['Authorization'] = `Bearer ${data.accessToken}`;
                            processQueue(null, data.accessToken);
                            resolve(axios(originalConfig));
                        })
                        .catch(err => {
                            processQueue(err, null);
                            TokenService.removeUser();
                            window.location.href = '/login';
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }
        }

        return Promise.reject(err);
    }
);

export default instance;