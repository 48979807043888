import React, { useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDailyExpenseContext } from '../../context/DailyExpenseContext';
import { DASHBOARD_PAGE_ROWS } from '../../helper/Constants';
import DateFormat from '../../helper/DateFormat';
import ExpenseDetailsFormat from '../../helper/ExpenseDetailsFormat';
import Loading from '../../helper/Loading';
import PaginationHelper from '../../helper/PaginationHelper';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import AddExpenseModal from './AddExpenseModal';
import DashboardTemplate from '../Sidebar/Template';
import ExpenseDetailsModal from './ExpenseDetailsModal';
import UpdateExpenseModal from './UpdateExpenseModal';
import DocumentTitle from '../../helper/DocumentTitle';
import { IoIosFunnel } from 'react-icons/io';

function HomePage() {

    const { currentMonthExpenses, allCategories, monthlyExpenses, isProcessing } = useDailyExpenseContext();
    const [rowsToShow, setRowsToShow] = useState([]);
    const [startIndex, setIndex] = useState([]);
    const [monthFilter, setMonthFilter] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [categoryFilter, setCategoryFilter] = useState();
    const [subCategoryFilter, setSubCategoryFilter] = useState();
    const [selectedExpense, setSelectedExpense] = useState();
    const [filteredList, setFilteredList] = useState(currentMonthExpenses);
    const [search, setSearch] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const divRef = useRef(null);
    useEffect(() => {
        divRef?.current?.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [rowsToShow])
    DocumentTitle("Daily Expenses");

    useEffect(() => {
        let filteredItems = [];
        let defaultMonth = Object.keys(monthlyExpenses)[0];
        filteredItems = monthFilter ? monthlyExpenses[monthFilter] : monthlyExpenses[defaultMonth];
        filteredItems = categoryFilter ? filteredItems?.filter(item => item.category.id === categoryFilter) : filteredItems;
        filteredItems = subCategoryFilter ? filteredItems?.filter(item => item.subCategory.id === subCategoryFilter) : filteredItems;
        const filerSearch = filteredItems?.filter(item => (item?.details?.toLowerCase().includes(search?.toLowerCase()) || item?.amount?.toString().includes(search)))
        setFilteredList(filerSearch ? filerSearch : [])
    }, [monthFilter, categoryFilter, subCategoryFilter, monthlyExpenses, search])

    return isProcessing ? <Loading /> : (
        <>
            <div className="md:flex gap-6 md:pl-6 overflow-y-auto" ref={divRef}>
                <div className="w-full md:flex-1 md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl text-headingColor font-bold capitalize ml-5 md:ml-10">
                                Expense Details
                            </p>
                            <span className="items-center md:w-auto md:pr-10 pr-5 mt-2.5 bg-white">
                                <button
                                    onClick={() => document.getElementById('Add_Expense_Modal').showModal()}
                                    type="button"
                                    className="flex items-center p-2 justify-center gap-1 text-white bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 focus:ring-4 focus:ring-purple-300 rounded-full font-semibold text-sm transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-105"
                                >
                                    <MdAdd className="w-5 h-5" />
                                    <span className="tracking-wide">Add Expense</span>
                                </button>
                            </span>
                        </div>
                        <div>
                            <hr className="w-40 h-1 mx-auto border-0 rounded bg-cust-red md:ml-10 ml-5" />
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 px-4 lg:px-8 py-2 bg-white'>
                        <div className='col-span-2 md:col-span-1'>
                            <button
                                onClick={() => setShowFilters(!showFilters)}
                                type="button"
                                className="flex items-center gap-2 px-3 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold text-sm rounded-full shadow-md hover:from-purple-600 hover:to-indigo-700 hover:shadow-lg focus:ring-4 focus:ring-purple-300 focus:outline-none transition-transform duration-300 ease-in-out transform hover:scale-105"
                            >
                                <IoIosFunnel className="w-5 h-5" />
                                {/* <span className="tracking-wide">Filter</span> */}
                            </button>
                        </div>
                        <div className='col-span-10 md:col-span-3 relative'>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="pl-11 border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Search Expense" />
                            </div>
                        </div>
                    </div>
                    {showFilters &&
                        <div className='grid grid-cols-12 gap-4 px-4 lg:px-8 py-2 bg-white'>
                            <div className='col-span-4 md:col-span-2'>
                                <label
                                    htmlFor="filterMonthInput"
                                    className="block mb-2 pl-1 text-sm font-medium  dark:text-white"
                                >
                                    Month
                                </label>
                                <select
                                    id="filterMonthInput"
                                    onInput={(e) => setMonthFilter(e.target.value)}
                                    className=" border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                >
                                    {Object.keys(monthlyExpenses)?.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-span-4 md:col-span-2'>
                                <label
                                    htmlFor="filterCategoryInput"
                                    className="block mb-2 pl-1 text-sm font-medium  dark:text-white"
                                >
                                    Category
                                </label>
                                <select
                                    id="filterCategoryInput"
                                    onInput={(e) => {
                                        setSelectedCategory(allCategories?.find(cat => cat.id === e.target.value));
                                        document.getElementById("filterSubCategoryInput").value = "";
                                        setCategoryFilter(e.target.value);
                                        setSubCategoryFilter("");
                                    }}
                                    className=" border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                >
                                    <option value="">All</option>
                                    {allCategories?.map((cat, index) => (
                                        <option key={index} value={cat.id}>{cat.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-span-4 md:col-span-2'>
                                <label
                                    htmlFor="filterSubCategoryInput"
                                    className="block mb-2 pl-1 text-sm font-medium  dark:text-white truncate"
                                >
                                    Sub-Category
                                </label>
                                <select
                                    id="filterSubCategoryInput"
                                    onInput={(e) => setSubCategoryFilter(e.target.value)}
                                    className=" border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                >
                                    <option value="">All</option>
                                    {selectedCategory?.subCategories?.map((cat, index) => (
                                        <option key={index} value={cat.id}>{cat.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    }
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white" style={{ scrollbarWidth: 'none' }}>
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white rounded-lg shadow-md scro">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10 bg-gray-100 shadow-sm">
                                        <tr className="shadow-inner">
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                #
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Date
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Expense Details
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowsToShow?.map((expense, index) => (
                                            <tr key={index} onClick={() => {
                                                setSelectedExpense(expense);
                                                document.getElementById('expense_details_modal').showModal()
                                            }} className="cursor-pointer border-b cursor-default border-neutral-300 transition duration-300 ease-in-out hover:scale-105 hover:bg-gray-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium text-gray-800 dark:text-gray-300">{startIndex + (index + 1)}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium text-gray-800 dark:text-gray-300"><DateFormat date={expense.date} /></td>
                                                <td className="md:whitespace-nowrap px-4 md:px-6 py-4 font-medium md:max-w-20 md:break-all text-gray-800 dark:text-gray-300"><ExpenseDetailsFormat category={expense.category.name} subCategory={expense.subCategory.name} details={expense.details} /></td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium text-gray-800 dark:text-gray-300"><PriceFormat price={expense.amount} /></td>
                                            </tr>
                                        ))}
                                        {rowsToShow?.length === 0 &&
                                            <tr><td colSpan={4} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <PaginationHelper filteredItems={filteredList} setRowsToShow={setRowsToShow} setIndex={setIndex} rowsLimit={DASHBOARD_PAGE_ROWS} />
                        </div>
                    </div>
                </div>
            </div>
            <AddExpenseModal />
            <ExpenseDetailsModal expense={selectedExpense} />
            <UpdateExpenseModal expense={selectedExpense} />
        </>
    )
}

const Home = () => {
    return <DashboardTemplate MainSection={HomePage()} Page={"Dashboard"} Heading={"Daily"} />
}

export default Home