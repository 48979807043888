const BACKEND_HOST = process.env.REACT_APP_PROD_BACKEND_HOST

export const API = {

    BACKEND_HOST: BACKEND_HOST,

    // Daily Expense APIs
    ADD_MAIN_CATEGORY: `${BACKEND_HOST}/dailyExpense/addCategory`,
    UPDATE_CATEGORY: `${BACKEND_HOST}/dailyExpense/updateCategory`,

    ADD_DAILY_EXPENSE: `${BACKEND_HOST}/dailyExpense/addExpense`,
    UPDATE_DAILY_EXPENSE: `${BACKEND_HOST}/dailyExpense/updateExpense`,

    FETCH_DAILY_EXPENSE_DATA: `${BACKEND_HOST}/dailyExpense/fetchAllData`,
    GET_ALL_CATEGORIES: `${BACKEND_HOST}/dailyExpense/categories`,

    // Occasional Expense APIs
    ADD_OCCASION: `${BACKEND_HOST}/occasionalExpense/addOccasion`,
    TOGGLE_OCCASION_STATUS: `${BACKEND_HOST}/occasionalExpense/occasion/#/toggleStatus`,

    ADD_OCCASIONAL_EXPENSE: `${BACKEND_HOST}/occasionalExpense/addExpense`,
    UPDATE_OCCASIONAL_EXPENSE: `${BACKEND_HOST}/occasionalExpense/updateExpense`,

    FETCH_OCCASIONAL_EXPENSE_DATA: `${BACKEND_HOST}/occasionalExpense/fetchAllData`,
    GET_OCCASIONS: `${BACKEND_HOST}/occasionalExpense/occasions`,

    // Authentication APIs
    LOGIN: `${BACKEND_HOST}/auth/login`,
    LOGIN_URI: '/auth/login',
    REGISTER: `${BACKEND_HOST}/auth/register`,
    REFRESH_TOKEN_URI: '/auth/refreshToken',
    REFRESH_TOKEN: `${BACKEND_HOST}/auth/refreshToken`,
    LOGOUT: `${BACKEND_HOST}/auth/logout`,

    // User APIs
    GET_ALL_USERS: `${BACKEND_HOST}/users`,
    TOGGLE_USER_STATUS: `${BACKEND_HOST}/users/#/toggleStatus`,

}

export const MSG = {
    INTERNAL_SERVER_ERROR: 'Internal Server Error',
    LOGIN_SUCCESS: 'Login Successful !!',
    LOGOUT_SUCCESS: 'Logout Successful !!',
    INVALID_USER: 'Invalid Mobile or Password !!',
    USER_INACTIVE: 'Your Account is Inactive !!',
    REGISTER_SUCCESS: 'Registration Successful !!',
    REGISTER_FAIL: 'User Already Registered !!',
    USER_STATUS_UPDATED: 'User Status Updated !!',
    NEW_CATEGORY_ADDED: 'New Category Added !!',
    NEW_SUB_CATEGORY_ADDED: 'Sub-Category Added !!',
    NEW_EXPENSE_ADDED: 'New Expense Added !!',
    EXPENSE_DETAILS_UPDATED: 'Expense Details Updated !!',
    NEW_OCCASION_ADDED: 'New Occasion Added !!',
    OCCASION_STATUS_UPDATED: 'Occasion Status Updated !!'
}

export const LOGO = require('../images/expense-logo.png');
export const SITE_NAME = 'Expense Tracker';
export const PAGE_TITLE_PREFIX = SITE_NAME

export const DASHBOARD_PAGE_ROWS = 50;
export const OCCASIONAL_HOME_PAGE_ROWS = 30;

export const CATEGORY_TO_INCLUDE_IN_DETAILS = [];

