import React, { useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useOccasionalExpenseContext } from '../../context/OccasionalExpenseContext';
import { OCCASIONAL_HOME_PAGE_ROWS } from '../../helper/Constants';
import DateFormat from '../../helper/DateFormat';
import DocumentTitle from '../../helper/DocumentTitle';
import Loading from '../../helper/Loading';
import PaginationHelper from '../../helper/PaginationHelper';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';
import AddExpenseModal from './AddExpenseModal';
import ExpenseDetailsModal from './ExpenseDetailsModal';
import UpdateExpenseModal from './UpdateExpenseModal';

function HomePage() {

    const { occasions, occasionWiseExpenses, isProcessing } = useOccasionalExpenseContext();
    const [rowsToShow, setRowsToShow] = useState([]);
    const [occasionFilter, setOccasionFilter] = useState();
    const [selectedExpense, setSelectedExpense] = useState();
    const [filteredList, setFilteredList] = useState();
    const [search, setSearch] = useState("");
    const divRef = useRef(null);
    DocumentTitle("Occasional Expenses");

    useEffect(() => {
        divRef?.current?.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [rowsToShow])

    useEffect(() => {
        let filteredItems = [];
        let defaultOccasion = occasions[0]?.id;
        filteredItems = occasionFilter ? occasionWiseExpenses[occasionFilter] : occasionWiseExpenses[defaultOccasion];
        const filerSearch = filteredItems?.filter(item => (item?.details?.toLowerCase().includes(search?.toLowerCase()) || item?.amount?.toString().includes(search)))
        setFilteredList(filerSearch ? filerSearch : [])
    }, [occasionFilter, occasionWiseExpenses, occasions, search])

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsTotal, setSelectedRowsTotal] = useState(0);

    const toggleRowSelection = (expense) => {
        setSelectedRows((prevSelectedRows) => {
            const isAlreadySelected = prevSelectedRows.includes(expense);
            const updatedSelectedRows = isAlreadySelected
                ? prevSelectedRows.filter((row) => row !== expense)
                : [...prevSelectedRows, expense];

            // Update total
            const totalAmount = updatedSelectedRows.reduce((total, row) => total + row.amount, 0);
            setSelectedRowsTotal(totalAmount);

            return updatedSelectedRows;
        });
    };

    // Toggle "Select All" functionality
    const toggleSelectAll = () => {
        if (selectedRows.length === filteredList.length) {
            // Deselect all
            setSelectedRows([]);
            setSelectedRowsTotal(0);
        } else {
            setSelectedRows(filteredList);
            const totalAmount = filteredList.reduce((total, expense) => total + expense.amount, 0);
            setSelectedRowsTotal(totalAmount);
        }
    };

    return isProcessing ? <Loading /> : (
        <>
            <div className="md:flex gap-6 md:pl-6 overflow-y-auto" ref={divRef}>
                <div className="w-full md:flex-1 md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl text-headingColor font-bold capitalize ml-5 md:ml-10">
                                Expense Details
                            </p>
                            <span className="items-center md:w-auto md:pr-10 pr-5 mt-2.5 bg-white">
                                <button
                                    onClick={() => document.getElementById('Add_Expense_Modal').showModal()}
                                    type="button"
                                    className="flex items-center p-2 justify-center gap-1 text-white bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 focus:ring-4 focus:ring-purple-300 rounded-full font-semibold text-sm transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-105"
                                >
                                    <MdAdd className="w-5 h-5" />
                                    <span className="tracking-wide">Add Expense</span>
                                </button>
                            </span>
                        </div>
                        <div>
                            <hr className="w-40 h-1 mx-auto border-0 rounded bg-cust-red md:ml-10 ml-5" />
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 px-4 lg:px-8 py-2 bg-white'>
                        <div className='col-span-5 md:col-span-2'>
                            <select
                                id="filterOccasion"
                                onInput={(e) => setOccasionFilter(e.target.value)}
                                className="border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            >
                                {occasions?.map((occasion, index) => (
                                    <option key={index} value={occasion.id}>{occasion.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-7 md:col-span-2 relative">
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="pl-11 border border-gray-300 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="Search Expense" />
                            </div>
                        </div>
                    </div>
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white" style={{ scrollbarWidth: 'none' }}>
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                {selectedRowsTotal !== 0 &&
                                    <div className="mb-2 mx-2 justify-self-end font-medium text-gray-500 dark:text-gray-300">
                                        Selected Expenses Total: <span className='text-gray-800 px-2'><PriceFormat price={selectedRowsTotal} /></span>
                                    </div>
                                }
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white rounded-lg shadow-md">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10 bg-gray-100 shadow-sm">
                                        <tr>
                                            <th className="px-4 md:px-6 pt-1 text-gray-800 dark:text-gray-200">
                                                {/* <input
                                                    type="checkbox"
                                                    onChange={toggleSelectAll}
                                                /> */}
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        onChange={toggleSelectAll}
                                                        className="sr-only peer"
                                                    />
                                                    <div onClick={(e) => e.stopPropagation()} className="w-5 h-5 bg-gray-50 rounded-md border-2 border-gray-300 shadow-sm peer-checked:bg-green-500 peer-checked:border-green-500 flex items-center justify-center transition duration-300">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="w-3 h-3 text-white hidden peer-checked:block"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                </label>
                                            </th>
                                            <th scope="col" className="px-2 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Date
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Expense Details
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4 text-gray-800 dark:text-gray-200">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowsToShow?.map((expense, index) => (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    setSelectedExpense(expense);
                                                    document.getElementById('expense_details_modal').showModal();
                                                }}
                                                className="cursor-pointer border-b cursor-default border-neutral-300 transition hover:bg-gray-100 duration-300 ease-in-out dark:border-white/10 dark:hover:bg-neutral-600"
                                            >
                                                <td onClick={(e) => e.stopPropagation()} className="px-4 md:px-6 py-4">
                                                    <label className="relative inline-flex items-center cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRows.includes(expense)}
                                                            onChange={() => toggleRowSelection(expense)}
                                                            className="sr-only peer"
                                                        />
                                                        <div className="w-5 h-5 bg-gray-50 rounded-md border-2 border-gray-300 shadow-sm peer-checked:bg-green-500 peer-checked:border-green-500 flex items-center justify-center transition duration-300">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="w-3 h-3 text-white hidden peer-checked:block"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </td>

                                                <td onClick={(e) => e.stopPropagation()} className="whitespace-nowrap px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-gray-300">
                                                    <DateFormat date={expense?.date} format={'D-MMM-YY'} />
                                                </td>
                                                <td className="md:whitespace-nowrap px-4 md:px-6 py-4 font-medium md:max-w-20 md:break-all text-gray-800 dark:text-gray-300">
                                                    {expense?.details}
                                                </td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium text-gray-800 dark:text-gray-300">
                                                    <PriceFormat price={expense?.amount} />
                                                </td>
                                            </tr>
                                        ))}
                                        {rowsToShow?.length === 0 &&
                                            <tr><td colSpan={4} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                                {selectedRowsTotal !== 0 &&
                                    <div className="mt-3 mx-2 justify-self-end font-medium text-gray-500 dark:text-gray-300">
                                        Selected Expenses Total: <span className='text-gray-800 px-2'><PriceFormat price={selectedRowsTotal} /></span>
                                    </div>
                                }
                            </div>
                            <PaginationHelper filteredItems={filteredList} setRowsToShow={setRowsToShow} rowsLimit={OCCASIONAL_HOME_PAGE_ROWS} />
                        </div>
                    </div>
                </div>
            </div>
            <AddExpenseModal />
            <ExpenseDetailsModal expense={selectedExpense} />
            <UpdateExpenseModal expense={selectedExpense} />
        </>
    )

}

const Home = () => {
    return <DashboardTemplate MainSection={HomePage()} Page={"Expenses"} Heading={"Occasional"} />
}

export default Home