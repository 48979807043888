import React, { useEffect, useMemo, useState } from 'react'

function PaginationHelper({ filteredItems, setRowsToShow, setIndex, rowsLimit }) {

    const [currentPage, setCurrentPage] = useState(0);
    const [customPagination, setCustomPagination] = useState([]);

    useEffect(() => {
        if (filteredItems) {
            setRowsToShow(filteredItems.slice(0, rowsLimit));
            setTotalPage(Math.ceil(filteredItems?.length > 0 && filteredItems?.length / rowsLimit));
        }
        // eslint-disable-next-line
    }, [filteredItems])

    const [totalPage, setTotalPage] = useState(
        Math.ceil(filteredItems?.length / rowsLimit)
    );

    const nextPage = () => {
        const startIndex = rowsLimit * (currentPage + 1);
        setIndex && setIndex(startIndex);
        const endIndex = startIndex + rowsLimit;
        const newArray = filteredItems.slice(startIndex, endIndex);
        setRowsToShow(newArray);
        setCurrentPage(currentPage + 1);
    };
    const changePage = (value) => {
        const startIndex = value * rowsLimit;
        setIndex && setIndex(startIndex);
        const endIndex = startIndex + rowsLimit;
        const newArray = filteredItems.slice(startIndex, endIndex);
        setRowsToShow(newArray);
        setCurrentPage(value);
    };
    const previousPage = () => {
        const startIndex = (currentPage - 1) * rowsLimit;
        setIndex && setIndex(startIndex);
        const endIndex = startIndex + rowsLimit;
        const newArray = filteredItems.slice(startIndex, endIndex);
        setRowsToShow(newArray);
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else {
            setCurrentPage(0);
        }
    };

    useMemo(() => {
        if (filteredItems) {
            setCustomPagination(
                Array(Math.ceil(filteredItems?.length > 0 && filteredItems?.length / rowsLimit)).fill(null)
            );
        }
        // eslint-disable-next-line
    }, [filteredItems]);


    return (
        <div className="w-full pt-3 md:pb-14 flex justify-center sm:justify-between flex-col sm:flex-row gap-5 mt-1.5 px-1 items-center">
            <div className="text-base md:text-lg">
                Showing {currentPage === 0 ? 1 : currentPage * rowsLimit + 1} to{" "}
                {currentPage === totalPage - 1
                    ? filteredItems?.length
                    : (currentPage + 1) * rowsLimit}{" "}
                of {filteredItems?.length} entries
            </div>
            <div className="flex md:pb-2 pb-16 sticky">
                <ul
                    className="flex justify-center items-center gap-x-[10px] z-30"
                    role="navigation"
                    aria-label="Pagination"
                >
                    <li
                        className={` prev-btn flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-solid border-[#E4E4EB] disabled] ${currentPage === 0
                            ? "bg-[#cccccc] pointer-events-none"
                            : " cursor-pointer"
                            } `}
                        onClick={previousPage}
                    >
                        <img src="https://www.tailwindtap.com/assets/travelagency-admin/leftarrow.svg" alt='pagination-1' />
                    </li>
                    {customPagination?.map((data, index) => (
                        <li
                            className={`flex items-center justify-center w-[36px] rounded-[6px] h-[34px] border-[1px] border-solid border-[2px] bg-[#FFFFFF] cursor-pointer ${currentPage === index
                                ? "text-blue-600  border-sky-500"
                                : "border-[#E4E4EB] "
                                }`}
                            onClick={() => changePage(index)}
                            key={index}
                        >
                            {index + 1}
                        </li>
                    ))}
                    <li
                        className={`flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-solid border-[#E4E4EB] ${(currentPage === totalPage - 1) || (totalPage === 0)
                            ? "bg-[#cccccc] pointer-events-none"
                            : " cursor-pointer"
                            }`}
                        onClick={nextPage}
                    >
                        <img src="https://www.tailwindtap.com/assets/travelagency-admin/rightarrow.svg" alt='pagination-2' />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PaginationHelper