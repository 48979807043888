import React, { useEffect, useRef } from 'react';
import { MdClose, MdEdit } from 'react-icons/md';
import PriceFormat from '../../helper/PriceFormat';
import DateFormat from '../../helper/DateFormat';

const ExpenseDetailsModal = ({ expense }) => {
    const divRef = useRef(null);
    useEffect(() => {
        divRef.current.scroll({
            top: 0,
        });
    })

    return (
        <>
            <dialog id="expense_details_modal" className="modal backdrop:bg-black/60 flex items-center justify-center">
                <div className="relative m-6 w-full max-w-md max-h-full md:max-w-2xl rounded-xl bg-gradient-to-br from-gray-100 to-white shadow-[0_10px_30px_rgba(0,0,0,0.3)] transform transition-all duration-300 hover:shadow-[0_20px_40px_rgba(0,0,0,0.5)]" ref={divRef}>
                    {/* Modal Content */}
                    <div className="relative bg-white rounded-xl shadow-lg overflow-hidden">
                        {/* Modal Header */}
                        <div className="py-4 px-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center bg-gradient-to-r from-blue-200 to-blue-100 shadow-inner">
                            <h3 className="text-lg font-bold text-gray-800 dark:text-white tracking-wide">Expense Details</h3>
                            <div className="flex space-x-5">
                                <button onClick={() => { document.getElementById('Update_Expense_Modal').showModal(); document.getElementById('expense_details_modal').close(); }} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 p-2 rounded-full bg-gradient-to-r from-green-300 to-green-400 shadow-[0_4px_8px_rgba(0,0,0,0.2)] hover:shadow-[0_6px_12px_rgba(0,0,0,0.3)] transform hover:scale-105 transition">
                                    <MdEdit className="w-5 h-5" />
                                </button>
                                <button onClick={() => document.getElementById('expense_details_modal').close()} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 p-2 rounded-full bg-gradient-to-r from-red-300 to-red-400 shadow-[0_4px_8px_rgba(0,0,0,0.2)] hover:shadow-[0_6px_12px_rgba(0,0,0,0.3)] transform hover:scale-105 transition">
                                    <MdClose className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                        {/* Modal Body */}
                        <div className="py-6 px-4 bg-white dark:bg-gray-800 rounded-b-xl">
                            <div className="space-y-4">
                                {[
                                    { label: "Date", value: <DateFormat date={expense?.date} format={"Do MMM YYYY"} /> },
                                    { label: "Category", value: expense?.category?.name },
                                    { label: "Sub-Cat", value: expense?.subCategory?.name },
                                    expense?.details && { label: "Details", value: expense?.details },
                                    { label: "Added By", value: expense?.user?.split(' ')[0] },
                                    { label: "Added On", value: <DateFormat date={expense?.createdOn} format={"Do MMM YYYY"} /> },
                                    { label: "Amount", value: <PriceFormat price={expense?.amount} /> },
                                ].map((item, index) => item && (
                                    <div key={index} className="grid grid-cols-12 gap-2 p-3 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-sm hover:shadow-lg transition-all">
                                        <p className="col-span-4 md:col-span-3 font-medium text-gray-600 dark:text-gray-400">{item.label}</p>
                                        <p className="col-span-1 text-gray-600 dark:text-gray-400">:</p>
                                        <p className="col-span-7 md:col-span-8 text-gray-800 dark:text-gray-200">{item.value}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        </>

    )
}

export default ExpenseDetailsModal