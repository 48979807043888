import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import DocumentTitle from '../../helper/DocumentTitle';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';

function UsersContent() {

    const { users, toggleUserStatus } = useAuthContext();
    DocumentTitle("Users");

    return (
        <>
            <div className="md:flex gap-6 md:pl-6">
                <div className="w-full md:w-1/2">
                    <div className='bg-white pb-2'>
                        <div className="w-full flex items-center justify-center py-4">
                            <p className="text-xl text-headingColor font-bold capitalize">
                                Users
                            </p>
                        </div>
                        <div>
                            <hr className="w-40 h-1 mx-auto border-0 rounded bg-cust-red" />
                        </div>
                    </div>
                    <div className="overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 max-h-[75vh] bg-white">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            {/* <th scope="col" className="px-4 md:px-6 py-4">
                                                #
                                            </th> */}
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                User
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Status
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users?.map((user, index) => (
                                            <tr key={index}
                                                className="cursor-pointer border-b border-neutral-300 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                                {/* <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{index + 1}</td> */}
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">
                                                    <p>{`${user?.firstName} ${user?.lastName}`}</p>
                                                    <p className='font-normal'>({user?.mobile})</p>
                                                </td>
                                                {user?.active ?
                                                    <td className="whitespace-nowrap px-4 md:px-6 py-4 font-bold text-xs md:text-sm text-green-600 uppercase"> Active</td> :
                                                    <td className="whitespace-nowrap px-4 md:px-6 py-4 font-bold text-xs md:text-sm text-red-600 uppercase">Inactive</td>
                                                }
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">
                                                    {user.active ?
                                                        <button
                                                            onClick={() => toggleUserStatus(user?.id)}
                                                            className="select-none rounded-lg bg-amber-500 py-2 px-3 text-center align-middle font-sans text-xs font-bold uppercase text-black shadow-md shadow-amber-500/20 transition-all hover:shadow-lg hover:shadow-amber-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                            type="button"
                                                        >
                                                            Deactivate
                                                        </button> :
                                                        <button
                                                            onClick={() => toggleUserStatus(user?.id)}
                                                            className="select-none rounded-lg bg-amber-500 py-2 px-3 text-center align-middle font-sans text-xs font-bold uppercase text-black shadow-md shadow-amber-500/20 transition-all hover:shadow-lg hover:shadow-amber-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                            type="button"
                                                        >
                                                            Activate
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                        {users?.length === 0 &&
                                            <tr><td colSpan={3} className='font-medium text-center md:text-base py-4'>No users to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const Users = () => {
    return <DashboardTemplate MainSection={UsersContent()} Page={"Users"} Heading={"Daily"} />
}

export default Users