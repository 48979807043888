import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { MdAdd, MdClose, MdRemove, MdSave } from 'react-icons/md';
import { useDailyExpenseContext } from '../../context/DailyExpenseContext';

const UpdateCategoryModal = () => {
    const { register, handleSubmit, control, formState: { errors }, reset } = useForm();
    const { fields, append, remove } = useFieldArray({ control, name: "subCategories" });
    const { isProcessing, updateCategory, selectedCategory } = useDailyExpenseContext();
    const [existingSubCatLength, setLength] = useState(0);

    const onSubmit = (data) => {
        document.getElementById('close_add_sub_cat_modal').click();
        updateCategory(data);
    };

    useEffect(() => {
        if (selectedCategory) {
            reset({
                id: selectedCategory.id,
                name: selectedCategory.name,
                createdOn: selectedCategory.createdOn,
                createdBy: selectedCategory.createdBy,
                subCategories: selectedCategory.subCategories
            });
            setLength(selectedCategory?.subCategories?.length)
        }
        // eslint-disable-next-line
    }, [selectedCategory])

    return (
        <dialog id="Add_SubCategory_Modal" className="modal">
            <div className="relative p-4 w-full max-w-md max-h-full md:max-w-xl overflow-scroll scrollbar-hidden">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {isProcessing &&
                        <div className="absolute w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
                            <svg aria-hidden="true" className="absolute w-8 h-8 text-gray-200 top-2/4 left-1/2 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold  dark:text-white">
                            {selectedCategory?.name}
                        </h3>
                        <form method="dialog">
                            <button id="close_add_sub_cat_modal" onClick={() => reset()}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover: rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <MdClose className="w-6 h-6" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </form>
                    </div>
                    {/* Modal body */}
                    <form className="p-4 md:p-5" onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-4 mb-4 md:grid-cols-3 grid-cols-2">
                            {fields.map((item, index) => (
                                <div key={index}>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            disabled={index < existingSubCatLength}
                                            hidden={true}
                                            className=" border border-gray-300  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Enter Sub-Category"
                                            {...register(`subCategories.${index}.name`, { required: "Required" })}
                                        />
                                        {errors.subCategories && errors.subCategories[index]?.name && <p className="text-red-500 text-xs italic">Sub-Category Required</p>}
                                    </div>
                                </div>

                            ))}
                        </div>

                        <button
                            type="button"
                            onClick={() => append({ name: "" })}
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5"
                        >
                            <MdAdd className="me-1 -ms-1 w-4 h-4" />
                            Add
                        </button>
                        {fields.length > existingSubCatLength &&
                            <button
                                type="button"
                                onClick={() => fields.length > existingSubCatLength && remove(fields.length - 1)}
                                className="text-white ml-5 inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5"
                            >
                                <MdRemove className="me-1 -ms-1 w-4 h-4" />
                                Remove
                            </button>
                        }
                        <div className='text-right'>
                            <button
                                type="submit"
                                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                <MdSave className="me-1 -ms-1 w-5 h-5" />
                                Save Category
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </dialog>

    )
}

export default UpdateCategoryModal