import React, { useEffect, useState } from 'react';
import { useDailyExpenseContext } from '../../context/DailyExpenseContext';
import DocumentTitle from '../../helper/DocumentTitle';
import Loading from '../../helper/Loading';
import PriceFormat from '../../helper/PriceFormat';
import '../../static/css/home.css';
import DashboardTemplate from '../Sidebar/Template';
import SubCategoyReportModal from './SubCategoyReportModal';

function CategoryReportPage() {

    const { monthlyExpenses, allCategories, isProcessing } = useDailyExpenseContext();
    const [monthFilter, setMonthFilter] = useState();
    const [categoryReport, setCategoryReport] = useState();
    const [subCategoryReport, setSubCategoryReport] = useState();
    const [mainCategoryNameForSubCatReport, setMainCategoryNameForSubCatReport] = useState();
    const [catAvgExpense, setCatAvgExpense] = useState();
    const [subCatAvgExpense, setSubCatAvgExpense] = useState();
    const [isCalculating, setIsCalculating] = useState(false);
    DocumentTitle("Category Report");

    useEffect(() => {
        setIsCalculating(true);
        let defaultMonth = Object.keys(monthlyExpenses)[0];
        let selectedMonthExpenses = monthFilter ? monthlyExpenses[monthFilter] : (defaultMonth ? monthlyExpenses[defaultMonth] : []);
        const catAmountCal = allCategories?.map(item2 => {
            const totalAmount = selectedMonthExpenses
                .filter(item1 => item1.category.id === item2.id)
                .reduce((sum, item1) => sum + item1.amount, 0);
            return { ...item2, expense: totalAmount };
        });
        setCategoryReport(catAmountCal);
        setIsCalculating(false);
    }, [monthFilter, allCategories, monthlyExpenses])

    useEffect(() => {
        setIsCalculating(true);
        const totalMonths = Object.keys(monthlyExpenses)?.length - 1;
        const mainCatExpensesMap = new Map();
        const subCatExpensesMap = new Map();
        allCategories?.forEach(category => {
            const totalAmount = Object.values(monthlyExpenses)
                .slice(1)
                .flat()
                .filter(expense => expense.category.id === category.id)
                .reduce((sum, expense) => sum + expense.amount, 0);

            const averageExp = totalMonths ? totalAmount / totalMonths : 0;

            mainCatExpensesMap.set(category.id, averageExp);
        });
        const allSubCategories = allCategories?.reduce((acc, category) => {
            return acc.concat(category.subCategories);
        }, []);
        allSubCategories.forEach(subCat => {
            const totalAmount = Object.values(monthlyExpenses)
                .slice(1)
                .flat()
                .filter(expense => expense.subCategory.id === subCat.id)
                .reduce((sum, expense) => sum + expense.amount, 0);

            const averageExp = totalMonths ? totalAmount / totalMonths : 0;

            subCatExpensesMap.set(subCat.id, averageExp);
        });
        setCatAvgExpense(mainCatExpensesMap);
        setSubCatAvgExpense(subCatExpensesMap);
        setIsCalculating(false);
    }, [allCategories, monthlyExpenses])

    function fetchSubCategoryReport(mainCatId) {
        setIsCalculating(true);
        let defaultMonth = Object.keys(monthlyExpenses)[0];
        let selectedMonthExpenses = monthFilter ? monthlyExpenses[monthFilter] : (defaultMonth ? monthlyExpenses[defaultMonth] : []);
        const mainCategory = allCategories?.find(cat => cat.id === mainCatId);
        const allSubCategories = mainCategory?.subCategories;
        const catAmountCal = allSubCategories?.map(item2 => {
            const totalAmount = selectedMonthExpenses
                .filter(item1 => item1.subCategory.id === item2.id)
                .reduce((sum, item1) => sum + item1.amount, 0);
            return { ...item2, expense: totalAmount };
        });
        setSubCategoryReport(catAmountCal);
        setMainCategoryNameForSubCatReport(mainCategory?.name)
        setIsCalculating(false);
        document.getElementById('sub_cat_report_modal').showModal();
    }

    return (isProcessing || isCalculating) ? <Loading /> : (
        <>
            <div className="md:flex gap-6 md:pl-6">
                <div className="w-full md:flex-1 md:w-1/2">
                    <div className='bg-white py-4'>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl text-headingColor font-bold capitalize ml-5 md:ml-10">
                                Category-wise Expenses
                            </p>
                            <span className="items-center md:w-auto md:pr-10 pr-5 bg-white">
                                <div className='mt-2'>
                                    <select
                                        id="filterMonthInput"
                                        onInput={(e) => setMonthFilter(e.target.value)}
                                        className="border border-gray-500 bg-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    >
                                        {Object.keys(monthlyExpenses)?.map((month, index) => (
                                            <option key={index} value={month}>{month}</option>
                                        ))}
                                    </select>
                                </div>
                            </span>
                        </div>
                        <div>
                            <hr className="w-56 h-1 mx-auto border-0 rounded bg-cust-red md:ml-10 ml-5" />
                        </div>
                    </div>
                    <div className="md:block overflow-x-auto scrollbar-x-hidden sm:-mx-6 md:mx-0 bg-white overflow-y-scroll max-h-[75vh] md:max-h-[80vh] flex" style={{ scrollbarWidth: 'none' }}>
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="">
                                <table className="min-w-full text-left text-sm font-light text-surface dark:text-white bg-white">
                                    <thead className="border-b border-neutral-400 font-medium dark:border-white/10">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                #
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Category
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Expenditure
                                            </th>
                                            <th scope="col" className="px-4 md:px-6 py-4">
                                                Average
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categoryReport?.map((cat, index) => (
                                            <tr key={index}
                                                onClick={() => { fetchSubCategoryReport(cat?.id) }}
                                                className="cursor-pointer border-b cursor-default border-neutral-300 transition hover:scale-105 hover:bg-gray-100 duration-300 ease-in-out dark:border-white/10 dark:hover:bg-neutral-600">
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{index + 1}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium">{cat?.name}</td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><PriceFormat price={cat?.expense} /></td>
                                                <td className="whitespace-nowrap px-4 md:px-6 py-4 font-medium"><PriceFormat price={catAvgExpense?.get(cat.id)} /></td>
                                            </tr>
                                        ))}
                                        {categoryReport?.length === 0 &&
                                            <tr><td colSpan={4} className='font-medium text-center md:text-lg py-4'>No items to display</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <SubCategoyReportModal subCategoryReport={subCategoryReport} subCatAvgExpense={subCatAvgExpense} isCalculating={isCalculating} mainCategory={mainCategoryNameForSubCatReport} />
            </div>
        </>
    )

}

const CategoryReport = () => {
    return <DashboardTemplate MainSection={CategoryReportPage()} Page={"Category Report"} Heading={"Daily"} />
}

export default CategoryReport